/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Fragment } from "react"

import SEO from "../components/Seo"

const ContributePage = () => (
  <Fragment>
    <SEO title="Contribute" />
    <div sx={{ variant: "contentPage" }}>
      <h1>Contribute!</h1>
      <section>
        <h2>Join the Effort</h2>
        <p>
          We try our very best to present the most accurate translation data,
          but sometimes this can be a challenge. Each language has its own set
          of rules and expressions. In some cases, a word might not have a clear
          or literal English equivalent.
        </p>
        <p>
          We have worked with local {process.env.GATSBY_LANGUAGE} speakers to
          craft this guide, but we recognize that all humans make mistakes. You
          may find an occasional typo, an innacurate gender, or a simple error.
        </p>
        <p>
          If you do spot an issue, please help out by submitting a revision:
        </p>
      </section>
      <StepSection
        sx={{ h4: { color: "primary" }, li: { fontSize: [16, 18] } }}
      >
        <ol>
          <li>
            <h4>Locate Edit Mode</h4>
            <div>
              Each phrase, vocab, and verb post has an <span>Editor</span>{" "}
              button near the top of the page. If you see an error or if you
              want to submit a new word to the list, click this button.
            </div>
          </li>
          <li>
            <h4>Choose an Edit Type</h4>
            <div>
              Inside <strong>Edit Mode</strong>, you can either add a new
              translation or edit an existing entry. Simply click a row to
              activate the Edit form, or click the <span>New</span> button to
              create a new translation. If you want to offer a synonym or an
              alternate translation, you can do so from the Edit form.
            </div>
          </li>
          <li>
            <h4>Submit Your Work</h4>
            <div>
              Fill out all of the necessary form fields, including your email
              address, and click <span>Submit</span>. We review each submission
              and add new submissions to the website each week. We'll send you
              an email when your entry is approved.
            </div>
          </li>
          <li>
            <h4>Membership Perks!</h4>
            <div>
              Anyone who submits an edit or adds new translations will be
              invited to create an early user account when that feature is
              ready.
            </div>
            <div>
              For now, we'll keep track of all your edits and add those stats to
              your profile. This process will become fully automatic when we
              introduce user accounts.
            </div>
          </li>
        </ol>
      </StepSection>
    </div>
  </Fragment>
)

const StepSection = styled.section`
  h4 {
    font-size: 22px;
  }
  ol {
    margin-top: 50px;
    list-style: decimal;
    margin-left: 30px;
  }

  li {
    padding: 0 15px;
    margin-bottom: 20px;

    span {
      display: inline-block;
      border: 1px solid gainsboro;
      padding: 3px 10px;
      border-radius: 2px;
      background: #fbfbfb;
      font-family: Avenir Next;
    }

    div {
      margin-bottom: 20px;
    }
  }
`

export default ContributePage
